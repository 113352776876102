import React, { useEffect } from 'react'
import { scroller as scroll } from 'react-scroll'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

export default function SectionID({ value, ...props }) {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.location.hash === `#${value}`) {
                scroll.scrollTo(value, {
                    delay: 0,
                    duration: 300,
                    smooth: 'easeInOutQuad',
                })
            }
        }
    }, [value])

    return (
        <div
            {...props}
            id={value}
            css={`
                background-color: red;
                height: 0;
                overflow: 0;
                position: relative;
                top: -5rem;
                z-index: 9999;

                @media ${MqTablet} {
                    top: -8rem;
                }

                @media ${MqDesktop} {
                    top: -10.5rem;
                }
            `}
        />
    )
}
